import { ChainId, Percent } from '@uniswap/sdk-core'
import JSBI from 'jsbi'

import { BestSwapNewChains } from './chains'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

// TODO(WEB-1984): Convert the deadline to minutes and remove unecessary conversions from
// seconds to minutes in the codebase.
// 30 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 30
export const L2_DEADLINE_FROM_NOW = 60 * 5

// transaction popup dismisal amounts
export const DEFAULT_TXN_DISMISS_MS = 10000
export const L2_TXN_DISMISS_MS = 5000

export const BIG_INT_ZERO = JSBI.BigInt(0)
export const BIG_INT_ONE = JSBI.BigInt(1)

// one basis JSBI.BigInt
const BIPS_BASE = JSBI.BigInt(10000)
export const ONE_BIPS = new Percent(BIG_INT_ONE, BIPS_BASE)

// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')
// eslint-disable-next-line
export const SITE_NAME = 'SpookySwap' as const
export const SHORT_SITE_NAME = 'Spooky' as const
//TODO: change governance token name when DAO lauches
export const GOVERNANCE_TOKEN_NAME = 'Boo' as const
export const SUPPORTED_MASTER_CHEF_VERSIONS = [2, 3]
export const MASTER_CHEF_VERSION_3 = 3 as const
// eslint-disable-next-line import/no-unused-modules
export const MAX_NUMBER_OF_HOPS = 2 as const
export const DEFAULT_CHAIN_ID = ChainId.SONIC
//TODO: Ideally we should be using the calendar year days per year
// But as a generalization and for consistency we will use 365
export const DAYS_IN_YEAR = 365
export const SECONDS_IN_DAY = 86400

export const LP_FEE_SHARE = 0.0017
export const LIQUIDITY_HUB_PARTNER_ID = 'spookyswap'
export const LIQUIDITY_HUB_NATIVE_ADDRESS = ZERO_ADDRESS
export const PARASWAP_PARTNER_ID = 'spookyswap'
export const PARASWAP_PARTNER_FTM_ADDRESS = '0x2e82aF7831B557610E2d19546aD2B9CA9d529147'
export const PARASWAP_FEE_BIPS = 50
export const PARASWAP_SUPPORTED_CHAINS = [ChainId.FANTOM, ChainId.MAINNET, ...BestSwapNewChains]
export const USE_CLIENT_QUOTING = [
  ChainId.BERA_TESTNET,
  ChainId.BIT_TORRENT_MAINNET,
  ChainId.EON,
  ChainId.SONIC_TESTNET,
  ChainId.SONIC,
]

export const BEST_SWAP_NAME = 'API'
export const DISCORD_LINK = 'https://discord.com/invite/weXbvPAH4Q'
export const GITHUB_LINK = 'https://github.com/SpookySwapV3'
export const TELEGRAM_LINK = 'https://t.me/SpookySwapCommunity'
// export const paraswapTaxBuy: { [key: string]: number } = {}
// export const paraswapTaxSell: { [key: string]: number } = {}

export const FANTOM_BOO_FARM_ADDRESS = '0xEc7178F4C41f346b2721907F5cF7628E388A7a58'
export const FANTOM_LZUSDC_FARM_ADDRESS = '0x90469ACbC4b6d877873CD4f1CCA54fDE8075A998'
